<template>
  <div
    class="flex rounded-[51px] bg-blue-dark w-fit h-[30px] p-0.5 cursor-pointer"
    @click="handleClick"
  >
    <WeightedUnweightedToggleItem
      :data-test-id="'weighted-toggle-button'"
      label="Weighted"
      :is-active="value === ValueType.WEIGHTED"
    />
    <WeightedUnweightedToggleItem
      :data-test-id="'unweighted-toggle-button'"
      label="Unweighted"
      :is-active="value === ValueType.UNWEIGHTED"
    />
  </div>
</template>

<script lang="ts" setup>
import { ValueType } from '~/constants/general'

type Props = {
  value: ValueType
}

const props = defineProps<Props>()

const emit = defineEmits<{
  onChangeValue: [event: ValueType]
}>()

const handleClick = () => {
  const newValue = props.value === ValueType.WEIGHTED ? ValueType.UNWEIGHTED : ValueType.WEIGHTED
  emit('onChangeValue', newValue)
}
</script>
