<template>
  <HelpSquareIcon id="weighted-unweighted-help-icon" class="text-white h-6 w-6 cursor-pointer" />
</template>

<script setup lang="ts">
import tippy, { hideAll } from 'tippy.js'
import HelpSquareIcon from '~/assets/icons/help-square.svg'

onMounted(() => {
  nextTick(() => {
    tippy('#weighted-unweighted-help-icon', {
      placement: 'bottom',
      theme: 'weight',
      arrow: true,
      allowHTML: true,
      onShow(instance) {
        const div = document.createElement('div')
        div.id = `tooltip-id-weighted-unweighted-help-content`
        div.style.zIndex = '91'
        div.classList.add('flex', 'flex-col', 'gap-4')
        div.setAttribute('data-testid', 'tooltip-weighted-unweighted-help')

        const spanTitleWeighted = document.createElement('span')
        spanTitleWeighted.setAttribute('data-testid', 'title-tooltip')
        spanTitleWeighted.style.fontWeight = '700'
        spanTitleWeighted.textContent = 'Weighted Supply'

        const spanWeighted = document.createElement('span')
        spanWeighted.style.fontWeight = '700'
        spanWeighted.textContent = 'weighted'

        const spanTitleUnweighted = document.createElement('span')
        spanTitleUnweighted.setAttribute('data-testid', 'title-tooltip')
        spanTitleUnweighted.style.fontWeight = '700'
        spanTitleUnweighted.textContent = 'Unweighted Supply'

        const divContentUnweighted = document.createElement('div')
        divContentUnweighted.innerHTML = `${spanTitleUnweighted.outerHTML} as forecasted on an asset by asset basis, not accounting for project status.`

        div.appendChild(divContentUnweighted)

        const divContentWeighted = document.createElement('div')
        divContentWeighted.innerHTML = `${spanTitleWeighted.outerHTML} is ${spanWeighted.outerHTML} according to project development status,
              Benchmark tracks announced projects based on the status of project finance and commissioning.
              Later in the forecast period more supply will be required from currently unknown sources.`

        div.appendChild(divContentWeighted)

        instance.setContent(`${div.outerHTML}`)
      },
    })
  })
})

onBeforeUnmount(() => {
  hideAll()
})
</script>
